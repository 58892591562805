import "slick-carousel";

import "slick-carousel/slick/slick.css";

$(document).ready(() => {
  $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnDotsHover: true,
    arrows: false,
    fade: true,
    appendDots: $("#homeDots"),
    autoplay: true,
    autoplaySpeed: 5000
  });
});
